<template>
	<div>
		<!-- 登录信息 -->
		<global-tips></global-tips>
		<el-card>
			<!-- 搜索框 -->
			<el-form v-show="showSearch" ref="queryForm" :model="queryForm" inline size="small" @submit.native.prevent>
				<el-form-item label="名称" label-width="50px" prop="keyword">
					<el-input v-model="queryForm.keyword" placeholder="请输入名称" type="text" clearable
						style="width: 230px" @keyup.enter.native="query"/>
				</el-form-item>
				<el-form-item label="类型" label-width="50px">
					<el-select v-model="queryForm.grade" multiple placeholder="请选择类型" size="small" class="w-100">
						<el-option label="全部" value=""></el-option>
						<el-option v-for="(item, index) in grades" :key="index" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" icon="el-icon-search" size="mini" @click="query">查询</el-button>
					<el-button icon="el-icon-refresh" size="mini" @click="resetQuery('queryForm')">重置</el-button>
				</el-form-item>
			</el-form>
			<!--自定义刷新组件-->
			<el-row :gutter="10" class="mb-1">
				<!-- <el-button type="primary" icon="el-icon-plus" size="mini" @click="handleAdd">新增</el-button> -->
				<el-radio-group v-model="queryForm.tab_index" @input="changeRadio">
					<el-radio-button label="0">已缴会员</el-radio-button>
					<el-radio-button label="10">未缴会员</el-radio-button>
					<el-radio-button label="1">待审核</el-radio-button>
					<el-radio-button label="2">开票申请</el-radio-button>
				</el-radio-group>
				<el-button type="primary" icon="el-icon-download" size="mini" style="margin-left: 20px;"
					@click.stop="downloadTemplate">下载模板</el-button>
				<el-upload class="upload-demo d-inline-block" ref="upload" action="#" :on-change="importExcel"
					:on-success="importSuccess" :on-error="importError" :before-upload="beforeImportUpload" :limit="1"
					:multiple="false" :show-file-list="false" accept=".xls, .xlsx" :auto-upload="false">
					<el-button slot="trigger" icon="el-icon-upload2" size="small" type="primary">导入</el-button>
				</el-upload>
				<el-button icon="el-icon-download" size="mini" type="primary" @click="downloadExcel">导出</el-button>
				<el-button type="danger" icon="el-icon-check" size="mini" @click="handleOperateAll"
					v-if="ids.length > 0 && queryForm.tab_index == 0">批量审核</el-button>
				<el-button type="primary" icon="el-icon-plus" size="mini" @click="handleAdd"
					v-if="queryForm.tab_index == 0">增加</el-button>
				<el-button type="primary" icon="el-icon-position" size="mini" @click="handleSms"
					v-if="queryForm.tab_index == 10">催缴短信</el-button>
				<right-toolbar :show-search.sync="showSearch" @queryTable="query" />
			</el-row>

			<!--表格-->
			<el-table v-loading="loading" size="small" :data="list" element-loading-text="Loading" highlight-current-row
				style="width: 100%" @selection-change="handleSelectionChange" v-if="queryForm.tab_index != 10">
				<el-table-column type="selection" width="55">
				</el-table-column>
				<el-table-column label="ID" width="80" align="center">
					<template slot-scope="scope">
						{{ (queryForm.page - 1) * queryForm.limit + scope.$index + 1 }}
					</template>
				</el-table-column>
				<el-table-column label="名称" min-width="110" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.name }}</span>
					</template>
				</el-table-column>
				<el-table-column label="类型" min-width="110" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.grade_name }}</span>
					</template>
				</el-table-column>
				<el-table-column label="金额" min-width="110" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.money || '' }}</span>
					</template>
				</el-table-column>
				<el-table-column label="记录" min-width="110" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.desc }}</span>
					</template>
				</el-table-column>
				<el-table-column label="缴费时间" min-width="110" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.date }}</span>
					</template>
				</el-table-column>
				<!-- 				<el-table-column label="缴费凭证" min-width="110" align="center" >
					<template slot-scope="scope">
						   <img :src="item.url" style="width: 30px;height: 30px;cursor: pointer;margin-right: 5px;" v-for="(item,index) in scope.row.album" @click="viewImage(item,'url')" v-if="scope.row.album.length > 0">
						   <img src="@/assets/images/empty.png" style="width: 30px;height: 30px;cursor: pointer;" v-else>
					  </template>
                </el-table-column> -->
				<el-table-column label="缴费凭证" min-width="110" align="center">
					<template slot-scope="scope">
						<div style="color: #2d8cf0;cursor: pointer;" @click="handleDownload(scope.row)"
							v-if="scope.row.album.length > 0">查看</div>
						<div v-else>无</div>
					</template>
				</el-table-column>
				<el-table-column label="状态" min-width="80" align="center">
					<template slot-scope="scope">
						<el-tag v-if="scope.row.status === 1" size="mini" type="danger">待审核</el-tag>
						<el-tag v-if="scope.row.status === 2" size="mini" type="warning">申请发票</el-tag>
						<el-tag v-if="scope.row.status === 3" size="mini" type="success">已开票</el-tag>
						<el-tag v-if="scope.row.status === 4" size="mini" type="danger">不通过</el-tag>
						<el-tag v-if="scope.row.status === 5" size="mini" type="success">已审核</el-tag>
					</template>
				</el-table-column>
				<el-table-column align="center" prop="utime" label="时间" min-width="170">
					<template slot-scope="scope">
						<i class="el-icon-time" />
						<span>{{ scope.row.utime }}</span>
					</template>
				</el-table-column>
				<el-table-column label="操作" min-width="200" align="center" fixed="right">
					<template slot-scope="scope">
						<el-tooltip class="item" effect="dark" content="审核" placement="top"
							v-if="scope.row.status == 1">
							<el-button type="warning" icon="el-icon-check" size="mini" circle
								@click="handleOperate(scope.row)"></el-button>
						</el-tooltip>
						<el-tooltip class="item" effect="dark" content="编辑" placement="top"
							v-if="scope.row.status == 1">
							<el-button type="primary" icon="el-icon-edit" size="mini" circle
								@click="handleEdit(scope.row)"></el-button>
						</el-tooltip>
						<el-tooltip class="item" effect="dark" content="发票" placement="top"
							v-if="scope.row.status == 2">
							<el-button type="warning" icon="el-icon-tickets" size="mini" circle
								@click="handleFp(scope.row)"></el-button>
						</el-tooltip>
						<el-tooltip class="item" effect="dark" content="删除" placement="top">
							<el-button type="danger" icon="el-icon-delete" size="mini" circle
								@click="handleDelete(scope.row.id)"></el-button>
						</el-tooltip>
					</template>
				</el-table-column>
			</el-table>
			<el-table v-loading="loading" size="small" :data="users" element-loading-text="Loading"
				highlight-current-row style="width: 100%" v-else>
				<el-table-column label="ID" width="80" align="center">
					<template slot-scope="scope">
						{{ scope.row.id }}
					</template>
				</el-table-column>
				<!-- 				<el-table-column label="封面" min-width="110" align="center">
				  <template slot-scope="scope">
					  <img :src="scope.row.avatar" style="width: 35px;height: 35px;" @click="viewImage(scope.row,'avatar')" v-if="scope.row.avatar">
					  <img src="@/assets/images/empty.png" style="width: 35px;height: 35px;" v-else>
				  </template>
				</el-table-column> -->
				<el-table-column label="名称" min-width="110" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.name }}</span>
					</template>
				</el-table-column>
				<el-table-column label="性质" align="center" prop="dw_xz"></el-table-column>
				<el-table-column label="法人" align="center" prop="legal_person"></el-table-column>
				<el-table-column label="手机" min-width="110" align="center" prop="l_mobile"></el-table-column>
				<el-table-column label="状态" min-width="60" align="center">
					<template slot-scope="scope">
						<el-tag size="mini" type="danger">未缴费</el-tag>
					</template>
				</el-table-column>
				<el-table-column align="center" prop="utime" label="时间" min-width="170">
					<template slot-scope="scope">
						<i class="el-icon-time" />
						<span>{{ scope.row.utime }}</span>
					</template>
				</el-table-column>
			</el-table>
			<pagination :total="total" auto-scroll :page.sync="queryForm.page" :limit.sync="queryForm.limit"
				@pagination="changeRadio(10)" v-if="queryForm.tab_index == 10" />
			<pagination :total="total" auto-scroll :page.sync="queryForm.page" :limit.sync="queryForm.limit"
				@pagination="changeRadio" v-else />
		</el-card>
		<!-- 编辑-->
		<el-dialog :visible.sync="open" width="600px" :close-on-click-modal="false" append-to-body :title="title">
			<el-form ref="form" :model="form" :rules="rules" label-width="80px" size="small">
				<el-form-item label="缴费会员" prop="user_id">
					<el-input :value="form.name" placeholder="请输入缴费会员ID" type="text" clearable @focus="selUsers" />
				</el-form-item>
				<el-form-item label="缴费时间" prop="date">
					<el-input v-model="form.date" placeholder="请输入缴费时间如:2023-08-08" type="text" clearable />
				</el-form-item>
				<el-form-item label="缴费金额" prop="money">
					<el-input v-model="form.money" placeholder="请输入缴费金额" type="number" clearable />
				</el-form-item>
				<el-form-item label="缴费记录" prop="desc">
					<el-input v-model="form.desc" placeholder="请输入缴费记录" type="text" clearable />
				</el-form-item>
				<!-- 			<el-form-item label="缴费凭证">
				<div class="flex flex-wrap">
					<div class="border rounded flex-all mr-3 mb-3 position-relative" style="width: 100px;height: 100px;line-height: 100px;cursor: pointer;"
					@click="chooseImage(index)"
					v-for="(item,index) in images" :key="index"
					v-dragging="{ item: item, list: images, group: 'imgs' }">
						<img :src="item.url" class="w-100 h-100" v-if="item.url">
						<i class="el-icon-plus text-muted" style="font-size: 30px;" v-else></i>
						<i class="el-icon-delete text-white position-absolute p-1" style="top: 0;right: 0;background-color: rgba(0,0,0,0.4);" 
						v-if="item.url"
						@click.stop="delImage(index)"></i>
					</div>
					<div class="border rounded flex-all mr-3 mb-3" style="width: 100px;height: 100px;line-height: 100px;cursor: pointer;"
					@click="chooseImage(-1)"
					v-if="images.length < 10"
					>
						<i class="el-icon-plus text-muted" style="font-size: 30px;"></i>
					</div>
				</div>
			</el-form-item> -->
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button size="mini" @click="cancel">取 消</el-button>
				<el-button type="primary" size="mini" @click="handleSubmit">确 定</el-button>
			</div>
		</el-dialog>

		<el-dialog :visible.sync="operate" width="600px" :close-on-click-modal="false" append-to-body :title="title">
			<el-form ref="operate" :model="operateForm" label-width="80px" size="small">
				<el-form-item label="审核状态">
					<el-radio-group v-model="operateForm.status">
						<el-radio :label="5">通过</el-radio>
						<el-radio :label="4">不通过</el-radio>
					</el-radio-group>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button size="mini" @click="operateCancel">取 消</el-button>
				<el-button type="primary" size="mini" @click="handleOperateSubmit">确 定</el-button>
			</div>
		</el-dialog>
		<!-- 发票地址 -->
		<el-dialog :visible.sync="fp" width="600px" :close-on-click-modal="false" append-to-body :title="title">
			<el-form ref="fp" :model="fpForm" label-width="80px" size="small">
				<el-form-item label="发票地址">
					<el-input v-model="fpForm.url" placeholder="请输入发票地址" type="text" clearable />
				</el-form-item>
				<el-form-item label="发票图片" prop="invoice">
					<el-upload class="upload-border" action="/manage/images/upload" :show-file-list="false"
						:on-success="uploadSuccess" :before-upload="beforeUpload">
						<img v-if="fpForm.invoice != ''" :src="fpForm.invoice" class="w-100 h-100">
						<i v-else class="el-icon-plus uploader-icon"></i>
					</el-upload>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button size="mini" @click="fpCancel">取 消</el-button>
				<el-button type="primary" size="mini" @click="handleFpSubmit">确 定</el-button>
			</div>
		</el-dialog>
		<!-- 选择用户 -->
		<el-dialog :visible.sync="openUser" width="800px" :close-on-click-modal="false" append-to-body :title="title">
			<el-table :data="usersData" style="width: 100%">
				<el-table-column label="ID" align="center" prop="id">
				</el-table-column>
				<!-- 			  <el-table-column
			  prop="url"
			  align="center"
			  label="头像">
			  <template slot-scope="scope">
			   <img :src="scope.row.url" style="width: 30px;height: 30px;cursor: pointer;" @click="viewImage(scope.row,'url')" v-if="scope.row.url">
			   <img src="@/assets/images/empty.png" style="width: 30px;height: 30px;cursor: pointer;" v-else>
			  </template>
			  </el-table-column> -->
				<el-table-column label="名称" align="center" prop="name">
				</el-table-column>
				<el-table-column label="电话" align="center" prop="l_phone">
				</el-table-column>
				<el-table-column width="200" align="right">
					<template slot="header" slot-scope="scope">
						<el-input v-model="page.keyword" size="mini" placeholder="输入关键字搜索">
							<el-button slot="append" icon="el-icon-search" size="mini" @click="getUsers"></el-button>
						</el-input>
					</template>
					<template slot-scope="scope">
						<el-button type="danger" size="mini" @click="handleSel(scope.$index, scope.row)">选择</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!-- 分页 -->
			<span slot="footer" class="dialog-footer">
				<div class="px-2" style="flex: 1;">
					<pagination :total="page.total" auto-scroll :page.sync="page.page" :limit.sync="page.size"
						@pagination="getUsers" />
				</div>
			</span>
		</el-dialog>
		<!-- 审核-->
		<el-dialog :visible.sync="office" width="800px" :close-on-click-modal="false" append-to-body :title="title">
			<iframe :src="file_url" frameborder="0" width="100%" height="600" v-if="isPdf"></iframe>
			<img :src="file_url" alt="" v-else>
			<div slot="footer" class="dialog-footer">
				<el-button size="mini" @click="office = false">取 消</el-button>
				<el-button type="primary" size="mini" @click="office = false">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import GlobalTips from "@/components/GlobalTips";
import common from "@/common/mixins/common.js"
export default {
	inject: ['app'],
	mixins: [common],
	components: {
		GlobalTips,
	},
	data() {
		return {
			isPdf: "true",
			preUrl: 'jfjl',
			queryForm: {
				page: 1,
				limit: 10,
				keyword: '',
				tab_index: 0,
				grade: [],
			},
			form: {
				user_id: '',
				name: '',
				date: '',
				desc: '',
				money: '',
				status: 1,
			},
			rules: {
				date: [
					{ required: true, message: '请输入缴费时间', trigger: 'blur' }
				],
				desc: [
					{ required: true, message: '请输入缴费记录', trigger: 'blur' }
				],
				money: [
					{ required: true, message: '请输入缴费金额', trigger: 'blur' }
				],
				user_id: [
					{ required: true, message: '请选择缴费会员', trigger: ['blur', 'change'] }
				],
			},
			images: [],
			operate: false,
			operates: [
				{ id: 1, name: '正常' },
				{ id: 2, name: '停用' },
				{ id: 3, name: '待审核' },
			],
			operateForm: {
				id: '',
				status: 3
			},
			fp: false,
			fpForm: {
				id: '',
				url: '',
				invoice: '',
			},
			page: {
				page: 1,
				limit: 10,
				total: 0,
			},
			users: [],
			ids: [],
			openUser: false,
			usersData: [],
			page: {
				page: 1,
				limit: 10,
				total: 0,
				keyword: '',
			},
			grades: [],
			office: false,
			file_url: "",
		}
	},
	mounted() {
		this.getGrades()
		const { action } = this.$route.query;
		if (action === '1') {
			this.queryForm.tab_index = '0'
			this.changeRadio(0);
		} else if (action === '2') {
			this.queryForm.tab_index = '10'
			this.changeRadio(10)
		}
	},
	methods: {
		handleSms(row) {
			this.$confirm('确定要给所有未缴会员发送催缴短信？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.axios.post('/manage/user/sms_wj', {}).then(res => {
					if (res.status) {
						this.$message({
							message: '发送成功',
							type: 'success'
						})
					}
					this.loading = false
				})
			})
		},
		getGrades() {
			this.axios.get('/manage/user_grade/all').then(res => {
				if (res.status) {
					let grades = res.data
					let index = grades.findIndex(v => v.id == 2)
					grades.splice(index, 1)
					this.grades = grades
				}
			})
		},
		reset() {
			this.form = {
				user_id: '',
				name: '',
				date: '',
				desc: '',
				money: '',
				status: 1,
			}
			this.resetForm('form')
		},
		query() {
			if (this.queryForm.tab_index == 0) {
				this.getList()
			} else {
				this.changeRadio(this.queryForm.tab_index)
			}
		},
		changeRadio(e) {
			
			if (e == 10) {
				this.axios.get(`/manage/user/wjf_user`, {
					params: this.queryForm
				}).then(res => {
					this.total = parseInt(res.total)
					this.users = res.data
				}).catch(err => {
					this.loading = false
				})
			} else {
				this.getList()
			}
		},
		handleOperate(row) {
			this.operate = true
			this.title = '审核'
			this.operateForm.id = row.id
			this.operateForm.status = row.status
		},
		operateCancel() {
			this.operate = false
		},
		handleOperateSubmit() {
			this.axios.post('/manage/' + this.preUrl + '/operate', this.operateForm).then(res => {
				if (res.status) {
					this.$message.success('审核成功')
					this.operate = false
					this.getList()
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		handleFp(row) {
			this.fp = true
			this.title = '发票'
			this.fpForm.id = row.id
		},
		fpCancel() {
			this.fp = false
			this.fpForm = this.$options.data().fpForm
		},
		handleFpSubmit() {
			this.axios.post('/manage/' + this.preUrl + '/fp', this.fpForm).then(res => {
				if (res.status) {
					this.$message.success('审核成功')
					this.fp = false
					this.getList()
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		downloadTemplate() {
			this.axios.get('/manage/' + this.preUrl + '/downloadTemplate', {
				responseType: 'arraybuffer',
			}).then(res => {
				const aLink = document.createElement("a");
				let blob = new Blob([res], { type: "application/vnd.ms-excel" });
				aLink.href = URL.createObjectURL(blob);
				aLink.download = '导入模板.xlsx';
				aLink.click();
				document.body.appendChild(aLink);
			})
		},
		importExcel(e) {
			//获取用户选择的文件
			const file = e.raw
			let formdata = new FormData()
			formdata.append('file', file)
			this.axios.post('/manage/' + this.preUrl + '/importExport', formdata).then(res => {
				this.$refs.upload.clearFiles()
				if (res.status) {
					this.$message.success('导入成功')
					this.getList()
				} else {
					this.$message.success('导入失败')
				}

			})
		},
		beforeImportUpload(file) {
			let fileArr = file.name.split('.')
			let suffix = fileArr[fileArr.length - 1]
			//只能导入.xls和.xlsx文件
			if (!/(xls|xlsx)/i.test(suffix)) {
				this.$message('文件格式不正确')
				return false
			}
			return true
		},
		importSuccess(e) {
			console.log(e)
			// 上传成功之后清除历史记录;否则只能上传一次
			this.$refs.upload.clearFiles()
		},
		importError(e) {
			console.log(e)
			this.$refs.upload.clearFiles()
		},
		handleOperateAll() {
			this.$confirm('确定要批量审核成功吗？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.loading = true
				this.axios.post('/manage/' + this.preUrl + '/operate_all', {
					ids: this.ids
				}).then(res => {
					if (res.status) {
						this.$message({
							message: '审核成功',
							type: 'success'
						})
						this.getList()
					}
					this.loading = false
				}).catch(err => {
					this.loading = false
				})
			})
		},
		downloadExcel(row) {
			this.axios.get('/manage/' + this.preUrl + '/downloadExcel', {
				responseType: 'arraybuffer',
				params: {
					page: this.queryForm.page,
					limit: this.queryForm.limit,
					keyword: this.queryForm.keyword,
					grade: this.queryForm.grade,
				}
			}).then(res => {
				console.log(res)
				const aLink = document.createElement("a");
				let blob = new Blob([res], { type: "application/vnd.ms-excel" });
				aLink.href = URL.createObjectURL(blob);
				aLink.download = '缴费记录.xlsx';
				aLink.click();
				document.body.appendChild(aLink);
			})
		},
		selUsers() {
			this.getUsers()
			this.$nextTick(() => {
				this.title = '用户列表'
				this.openUser = true
			})
		},
		getUsers() {
			this.axios.get('/manage/user/list', {
				params: {
					page: this.page.page,
					limit: this.page.limit,
					keyword: this.page.keyword,
				}
			}).then(res => {
				this.page.total = res.total
				this.usersData = res.data
			})
		},
		handleSel(index, item) {
			this.form.user_id = item.id
			this.form.name = item.name
			this.openUser = false
			this.title = '新增'
		},
		chooseImage(index) {
			const MAX = 4
			let conut = MAX - this.images.length
			this.app.chooseImage(res => {
				let list = []
				if (index === -1) {
					list = [...this.images, ...res]
				} else {
					list = [...this.images]
					list[index] = res[0]
				}
				this.images = list
			}, index === -1 ? conut : 1)
		},
		delImage(index) {
			this.$confirm('删除该文件, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				let list = [...this.images]
				list.splice(index, 1)
				this.images = list
			}).catch(() => {

			});
		},
		handleEdit(row) {
			this.reset()
			this.open = true
			this.title = '编辑'
			let data = JSON.parse(JSON.stringify(row))
			this.form = data
			this.images = data.album
		},
		handleSubmit() {
			this.$refs.form.validate(valid => {
				if (valid) {
					if (this.form.sort) {
						this.form.sort = parseInt(this.form.sort)
					}
					this.form.images = this.images
					if (this.form.id === undefined) {
						this.axios.post('/manage/' + this.preUrl + '/add', this.form).then(res => {
							if (res.status) {
								this.$message.success('添加成功')
								this.open = false
								this.getList()
							} else {
								this.$message.error(res.msg)
							}
						})
					} else {
						this.axios.put('/manage/' + this.preUrl + '/edit/' + this.form.id, this.form).then(res => {
							if (res.status) {
								this.$message.success('修改成功')
								this.open = false
								this.getList()
							} else {
								this.$message.error(res.msg)
							}
						})
					}
				}
			})
		},
		handleDownload(item) {
			if (item.album[0].path) {
				this.isPdf = true
				this.file_url = ''
				this.office = true
				this.file_url = 'https://api.mem.ahpea.cn/' + item.album[0].path
			} else {
				this.isPdf = false
				this.office = true
				this.file_url = item.album[0].url
			}

		},
		uploadSuccess(e) {
			if (e.status) {
				this.fpForm.invoice = e.data.url
			}
		},
		beforeUpload() { },
	}
}
</script>

<style></style>